<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/jobs' }">岗位管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增岗位</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="">
          <el-tree
            :data="departmentTreeData"
            node-key="id"
            :props="defaultProps"
            >
            <div class="custom-tree-node" slot-scope="{ node, data }" >
              <span @click.stop="">
                <el-radio
                 v-model="form.departmentId"
                 :label="data.id" 
                 >
                  {{ node.label }}
                 </el-radio>
              </span>
            </div>
          </el-tree>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          <el-button size="small" @click="$router.push('/jobs')">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
      },
      departmentTreeData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      id: 0
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$ajax.post("positionCreate", this.form).then((res) => {
            this.loading = false
            this.$message.success('成功')
            this.$router.push({ name: 'jobs'})
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    loadDepartmentList() {
      this.$ajax.get('departmentTree').then(res => {
        this.departmentTreeData = res.data
      })
    },
  },
  mounted() {
    this.loadDepartmentList()
    if (this.$route.params == null) {
      this.$router.push('/jobs')
    } else {
      this.form.parentId = this.$route.params.parentId
      this.id = this.$route.params.parentId
    }
  }
}
</script>

<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
</style>